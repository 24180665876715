import { ChangeDetectorRef, inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';

import { ApiFieldsService, CCURegionId, FieldTypes } from '@profilum-library';

import { UtilsService } from '../../../dashboard/backend-services/utils.service';
import { IRegion } from '../../../interfaces/iregion';
import { ITaskFields, TaskTypes } from '../../../../pages/control-panel/admin/lessons/add-lesson/add-lesson.component';
import { ITask } from '../../../interfaces/itask';
import { IButterflyControlElement } from '../../butterfly-control/butterfly-control.component';
import { ILesson } from '../../../interfaces/ilesson';
import {
  ICourseMaterial,
  ILessonMaterial,
} from '../../../../pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import { IField, ISurvey, ITest, IVariant } from '../../../../pages/control-panel/admin/lessons/interfaces/interfaces';
import { IFilter } from '../../dropdown-with-filter/dropdown-with-filter.component';
import { EmptyGuid } from '../../../global-constants/constants';
import { RegionsService } from '../../../../pages/control-panel/admin/regions/regions.service';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Injectable()
export class EditLessonHelperComponent extends UnsubscribeComponent {
  public lesson: ILesson;
  public form: UntypedFormGroup;
  public submitted = false;
  public courseMaterials: ICourseMaterial[];
  public lessonsMaterials: ILessonMaterial[];
  public tests: ISurvey[];
  public fields: IField[];
  public taskTypes: { taskType: TaskTypes; name: string }[] = [
    {
      taskType: TaskTypes.ScreeningTest,
      name: 'Прохождение диагностики',
    },
    {
      taskType: TaskTypes.ChooseProfessionsFromField,
      name: 'Выбор профессии',
    },
    {
      taskType: TaskTypes.SelectionOfProfessions,
      name: 'Отбор профессий',
    },
    {
      taskType: TaskTypes.ExternalUrl,
      name: 'Задание с URL',
    },
  ];
  public selectionStages: {
    name: string;
    stageType: number;
  }[] = [
    {
      name: 'Отсев по интересам (1 этап)',
      stageType: 1,
    },
    {
      name: 'Отсев по способностям (2 этап)',
      stageType: 2,
    },
    {
      name: 'Отраслевой отбор (3 этап)',
      stageType: 3,
    },
    {
      name: 'Ранжирование (4 этап)',
      stageType: 4,
    },
  ];
  public selectedCourseItemByDefault: IFilter;
  public selectedLessonItemByDefault: IFilter;
  public isLessonWithoutTasks = true;
  public taskFields: ITaskFields[];
  public taskCount = 0;
  public regions: IRegion[];
  public regionsObj: Record<string, IRegion> = {};
  public defaultRegionItem: IFilter;
  public defaultFieldItem;

  public fieldTypes: any = [];
  public isRegionDropdownDisabled = false;
  protected readonly newGuid: string;
  protected selectedFiles: FileList;
  protected defaultRegionId: string = EmptyGuid;
  protected CCURegionId: string = CCURegionId;

  constructor(
    protected utilsService: UtilsService,
    protected router: Router,
    protected regionsService: RegionsService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected apiFieldsService: ApiFieldsService,
  ) {
    super();
    this.utilsService = inject(UtilsService);
    this.router = inject(Router);
    this.newGuid = this.utilsService.newGuid();
    (this.taskFields as any) = [{}, {}];
  }

  get formControls() {
    return this.form.controls;
  }

  protected getAllRegions(): Promise<IRegion[]> {
    return this.regionsService
      .getAllRegions()
      .pipe(
        tap(regions => {
          this.regions = regions.sort((a, b) => (a.name > b.name ? 1 : -1));
          this.convertRegionsArrayToObject();

          const defaultRegion = this.regionsObj[this.defaultRegionId];

          if (defaultRegion) {
            this.defaultRegionItem = { id: defaultRegion.id, name: defaultRegion.name };
          }
        }),
      )
      .toPromise();
  }

  protected convertRegionsArrayToObject(): void {
    this.regionsObj =
      this.regions?.reduce((prev, current) => {
        prev[current.id] = current;

        return prev;
      }, {}) ?? {};
  }

  protected validateTask(taskFields: ITaskFields): boolean {
    const {
      taskType,
      tooltip,
      screeningTestId,
      fieldId,
      professionTryingOnStage,
      externalLessonTooltip,
      externalLessonLink,
    } = taskFields;

    switch (taskType) {
      case 6:
        return !!(screeningTestId && tooltip);
      case 7:
        return !!(fieldId && tooltip);
      case 8:
        return !!(professionTryingOnStage && tooltip);
      case 10:
        return !!(externalLessonTooltip && externalLessonLink);
      default:
        return false;
    }
  }

  protected createTask(taskFields: ITaskFields): ITask | null {
    if (!this.validateTask(taskFields)) {
      return null;
    }

    const {
      id,
      taskType,
      tooltip,
      screeningTestId,
      fieldId,
      chosenProfessions,
      professionTryingOnStage,
      required = false,
      tinderType = 0,
      externalLessonLink,
      externalLessonTooltip,
    } = taskFields;

    switch (taskType) {
      case 6:
        return {
          ...(id && { id }),
          type: TaskTypes.ScreeningTest,
          text: tooltip,
          required,
          screeningTestTask: {
            screeningTestId,
          },
        };
      case 7:
        return {
          ...(id && { id }),
          type: TaskTypes.ChooseProfessionsFromField,
          text: tooltip,
          tinderType,
          required,
          chooseProfessionsFromFieldTask: {
            fieldId,
            professionIds: chosenProfessions?.map(profession => profession.id) ?? [],
          },
        };
      case 8:
        return {
          ...(id && { id }),
          type: TaskTypes.SelectionOfProfessions,
          text: tooltip,
          required,
          professionTryingOnStage: professionTryingOnStage,
        };
      case 10:
        return {
          ...(id && { id }),
          type: TaskTypes.ExternalUrl,
          text: externalLessonTooltip,
          required,
          externalUrl: externalLessonLink,
        };
      default:
        return null;
    }
  }

  protected createTasks(): ITask[] | null {
    const tasks: ITask[] = [];

    for (let i = 0; i < this.taskCount; i++) {
      tasks.push(this.createTask(this.taskFields[i]));
    }

    return tasks;
  }

  protected setProfessions(event: IButterflyControlElement[], index: number): void {
    this.taskFields[index].chosenProfessions = event;
  }

  protected setValue(
    event: IFilter,
    data: ICourseMaterial[] | ILessonMaterial[] | ITest[] | IVariant[] | IField[],
    formControlName: string,
    fieldName: string,
  ): void {
    if (event) {
      this.formControls[formControlName].setValue(data[event.id][fieldName]);
      return;
    }

    this.formControls[formControlName].setValue('');
  }

  public selectedFieldType(event: IFilter): void {
    this.fields = null;
    // если выбран депкульт - ставить в регион ОКЦ и лочить для смены
    // иначе - очищаем регион и разблокируем его
    this.apiFieldsService.getAllFields(this.fieldTypes[event.id]?.type).subscribe(response => {
      this.fields = response.fields;

      if (event.id === FieldTypes.DepCult) {
        this.defaultFieldItem = { id: 0, name: this.fields[0].name, selected: true };

        const ccuRegionIndex = this.regions.findIndex(region => region.id === this.CCURegionId);
        this.defaultRegionItem = { id: ccuRegionIndex, name: this.regions[ccuRegionIndex].name, selected: true };

        this.isRegionDropdownDisabled = true;
      } else {
        this.defaultFieldItem = null;
        this.defaultRegionItem = this.regions.find(region => region.id === EmptyGuid);
        this.isRegionDropdownDisabled = false;
      }
      this.changeDetectorRef.detectChanges();
    });
  }
}
