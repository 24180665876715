import { Services } from '../services/services';
import {
  DevDomains,
  DomainsByConfiguration,
  KevinSoftDomainByConfiguration,
  ProdDomains,
  ScreeningTestsApiDomainByConfiguration,
  TestEditorDomainByConfiguration,
} from '../domains/domains';
import { Configurations } from '../configurations/configurations';

const isInEnvironment = (service: Services, configuration: Configurations): boolean => {
  if (typeof window === 'undefined') {
    return (globalThis?.process.env.NX_TASK_TARGET_CONFIGURATION ?? Configurations.Production) === configuration;
  }

  return window.location.hostname === new URL(DomainsByConfiguration[configuration][service]).hostname;
};

export const isDevEnvironment = (service: Services): boolean => {
  if (typeof window !== 'undefined' && /(localhost)/g.test(window.location.hostname)) {
    return true;
  }

  return isInEnvironment(service, Configurations.Development);
};

export const isPreProdEnvironment = (service: Services): boolean => {
  return isInEnvironment(service, Configurations.PreProduction);
};

export const isProdEnvironment = (service: Services): boolean => {
  return isInEnvironment(service, Configurations.Production);
};

export const getDomain = (service: Services) => {
  if (typeof window !== 'undefined') {
    return isDevEnvironment(service) ? DevDomains[service] : `${window.location.protocol}//${window.location.hostname}`;
  }

  const configurationFromEnv = globalThis?.process.env.NX_TASK_TARGET_CONFIGURATION ?? Configurations.Production;

  return DomainsByConfiguration[configurationFromEnv]?.[service] ?? ProdDomains[service];
};

export const getKevinSoftDomain = (service: Services) => {
  const domain = getDomain(service);

  return KevinSoftDomainByConfiguration[domain];
};

export const navigateToKevinSoftDomain = (service: Services)=> {
  const currentUrl = new URL(window.location.href);
  const returnUrl = currentUrl.searchParams.get('returnUrl');

  if (returnUrl) {
    try {
      const kevinSoftDomain = getKevinSoftDomain(service);
      const kevinSoftUrl = new URL(returnUrl);

      if (kevinSoftUrl.hostname.endsWith(kevinSoftDomain)) {
        window.location.href = returnUrl;
      }
    } catch (error) {
      console.error('error on redirect to returnUrl');
    }
  }
};

export const getScreeningTestsApiDomain = (service: Services) => {
  const domain = getDomain(service);

  return ScreeningTestsApiDomainByConfiguration[domain];
};

export const getTestEditorDomain = (service: Services) => {
  const domain = getDomain(service);

  return TestEditorDomainByConfiguration[domain];
};
