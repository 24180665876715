<a [routerLink]="'/admin/lessons'" class="button white-button stud-page-back-btn w-button"> Назад </a>

<div *ngIf="!isLoading" class="event-page-section create">
  <h1 class="event-h1">{{ 'SHARED.EDITING' | translate }} занятия</h1>
  <div class="create-event-form w-form">
    <form [formGroup]="form">
      <div class="event-image uploaded-photo" [style.background-image]="'url(' + formControls.imageUrl.value + ')'">
        <a class="white-button change-event-photo w-button" (click)="upload.click()"> Изменить фото </a>
        <input type="file" (change)="uploadLogoLesson($event)" #upload hidden />
      </div>
      <div class="event-image-text">Рекомендуем загружать качественные фото.</div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="videoUrl">Видео</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            name="videoUrl"
            placeholder="Ссылка на видео"
            id="videoUrl"
            formControlName="videoUrl"
          />
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="name">Название*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            [class.error-field]="submitted && formControls.name.errors"
            name="name"
            placeholder="Название"
            id="name"
            formControlName="name"
          />
          <ng-template [ngIf]="submitted && formControls.name.errors">
            <mat-error *ngIf="formControls.name.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-template>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="name">Порядковый номер*</label>
        </div>
        <div class="create-form-column">
          <input
            type="number"
            class="create-event-field w-input"
            [class.error-field]="submitted && formControls.orderNumber.errors"
            name="orderNumber"
            placeholder="Порядковый номер"
            id="orderNumber"
            formControlName="orderNumber"
          />
          <ng-template [ngIf]="submitted && formControls.orderNumber.errors">
            <mat-error *ngIf="formControls.orderNumber.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-template>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="hrid">HRID занятия*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            [class.error-field]="submitted && formControls.hrid.errors"
            name="hrid"
            placeholder="HRID занятия"
            id="hrid"
            formControlName="hrid"
          />
          <ng-template [ngIf]="submitted && formControls.hrid.errors">
            <mat-error *ngIf="formControls.hrid.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-template>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="text">Описание</label>
        </div>
        <div class="create-form-column">
          <quill-editor
            [styles]="{ height: '150px', border: '2px solid rgba(53, 53, 53, 0.1)' }"
            id="text"
            name="text"
            placeholder="Описание"
            formControlName="text"
          >
          </quill-editor>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Курс карьерной грамотности*</label>
        </div>
        <div class="create-form-column">
          <prf-dropdown-with-filter
            *ngIf="courseMaterials"
            [items]="courseMaterials"
            [selectedItemByDefault]="selectedCourseItemByDefault"
            (selectedItemEvent)="selectedCourse($event)"
            [placeholder]="'Выберите курс'"
          ></prf-dropdown-with-filter>
          <ng-template [ngIf]="submitted && formControls.courseMaterialId.errors">
            <mat-error *ngIf="formControls.courseMaterialId.errors.required">
              {{ 'SHARED.FORM.FILL_DROPDOWN' | translate }}
            </mat-error>
          </ng-template>
        </div>
      </div>
      <div *ngIf="lessonsMaterials?.length" class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Занятие курса карьерной грамотности*</label>
        </div>
        <div class="create-form-column">
          <prf-dropdown-with-filter
            [items]="lessonsMaterials"
            [selectedItemByDefault]="selectedLessonItemByDefault"
            (selectedItemEvent)="selectedLesson($event)"
            [placeholder]="'Выберите занятие'"
          ></prf-dropdown-with-filter>
          <ng-template [ngIf]="submitted && formControls.lessonMaterialId.errors">
            <mat-error *ngIf="formControls.lessonMaterialId.errors.required">
              {{ 'SHARED.FORM.FILL_DROPDOWN' | translate }}
            </mat-error>
          </ng-template>
        </div>
      </div>
      <div class="create-form-row">
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" (change)="setLessonWithoutTasks($event)">
          <mat-radio-button class="example-radio-button" [checked]="isLessonWithoutTasks" [value]="0">Без заданий</mat-radio-button>
          <mat-radio-button class="example-radio-button" [checked]="taskCount === 1" [value]="1">Одно задание</mat-radio-button>
          <mat-radio-button class="example-radio-button" [checked]="taskCount === 2" [value]="2">Два задания</mat-radio-button>
        </mat-radio-group>
      </div>
      <ng-template [ngIf]="!isLessonWithoutTasks">
        <ng-container *ngFor="let taskField of taskFields; let i = index">
          <ng-template [ngIf]="i + 1 <= taskCount">
            <div class="create-form-row">
              <div class="create-form-column _1">
                <label class="create-form-label">Задание {{ i + 1 }}</label>
              </div>
              <div class="create-form-column">
                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" (change)="setTaskType($event, i)">
                  <mat-radio-button
                    class="example-radio-button"
                    *ngFor="let task of taskTypes; let i = index"
                    [checked]="taskField.taskType === task.taskType"
                    [value]="task.taskType"
                  >
                    {{ task.name }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <ng-container [ngSwitch]="taskField?.taskType">
              <ng-template [ngSwitchCase]="6">
                <div class="create-form-row">
                  <div class="create-form-column _1">
                    <label class="create-form-label">Тест*</label>
                  </div>
                  <div class="create-form-column">
                    <prf-dropdown-with-filter
                      [items]="tests"
                      (selectedItemEvent)="selectedTest($event, i)"
                      [selectedItemByDefault]="taskField.selectedScreeningTestItem"
                      [placeholder]="'Выберите тест для типа задания прохождение диагностики'"
                    ></prf-dropdown-with-filter>
                    <mat-error *ngIf="submitted && !taskField.screeningTestId">
                      {{ 'SHARED.FORM.FILL_DROPDOWN' | translate }}
                    </mat-error>
                  </div>
                </div>
                <div class="create-form-row">
                  <div class="create-form-column _1">
                    <label class="create-form-label">Текст для тултипа*</label>
                  </div>
                  <div class="create-form-column">
                    <input
                      type="text"
                      class="create-event-field w-input"
                      [name]="'tooltipST' + i"
                      [formControlName]="'tooltipST' + i"
                      [class.error-field]="submitted && !taskField.tooltip"
                      placeholder="Текст для тултипа"
                      [(ngModel)]="taskField.tooltip"
                    />
                    <mat-error *ngIf="submitted && !taskField.tooltip">
                      {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                    </mat-error>
                  </div>
                </div>
                <div class="create-form-row">
                  <mat-checkbox class="checkbox" [name]="'required' + i" [formControlName]="'required' + i" [(ngModel)]="taskField.required"
                    >Обязательное задание?</mat-checkbox
                  >
                </div>
              </ng-template>
              <ng-template [ngSwitchCase]="7">
                <div class="create-form-row">
                  <div class="create-form-column _1">
                    <label class="create-form-label">Тип отраслей*</label>
                  </div>
                  <div class="create-form-column">
                    <prf-dropdown-with-filter
                      [items]="fieldTypes"
                      [selectedItemByDefault]="initialFieldType"
                      (selectedItemEvent)="selectedFieldType($event)"
                      [placeholder]="'Выберите тип отраслей'"
                    ></prf-dropdown-with-filter>
                    <mat-error *ngIf="submitted && !taskField.fieldId">
                      {{ 'SHARED.FORM.FILL_DROPDOWN' | translate }}
                    </mat-error>
                  </div>
                </div>
                <div class="create-form-row" *ngIf="fields">
                  <div class="create-form-column _1">
                    <label class="create-form-label">Отрасль*</label>
                  </div>
                  <div class="create-form-column">
                    <prf-dropdown-with-filter
                      [items]="fields"
                      [selectedItemByDefault]="taskField.selectedFieldItem"
                      (selectedItemEvent)="selectedField($event, i)"
                      [placeholder]="'Выберите отрасль для типа задания с выбором профессий'"
                    ></prf-dropdown-with-filter>
                    <mat-error *ngIf="submitted && !taskField.fieldId">
                      {{ 'SHARED.FORM.FILL_DROPDOWN' | translate }}
                    </mat-error>
                  </div>
                </div>
                <div *ngIf="taskField.fieldId" class="create-form-column">
                  <div class="create-form-row" [class.disabled-dropdown]="isRegionDropdownDisabled">
                    <div class="create-form-column _1">
                      <label class="create-form-label">Регион*</label>
                    </div>
                    <div class="create-form-column">
                      <prf-dropdown-with-filter
                        [items]="regions"
                        (selectedItemEvent)="selectedRegion($event, i)"
                        [placeholder]="'Выберите регион для фильтра профессий'"
                        [selectedItemByDefault]="defaultRegionItem"
                      ></prf-dropdown-with-filter>
                      <mat-error *ngIf="submitted && !taskField.fieldId">
                        {{ 'SHARED.FORM.FILL_DROPDOWN' | translate }}
                      </mat-error>
                    </div>
                  </div>
                </div>
                <div *ngIf="taskField.professions?.length" class="create-form-row">
                  <div class="create-form-column _1">
                    <label class="create-form-label">Профессии*</label>
                  </div>
                  <div class="create-form-column">
                    <prf-butterfly-control
                      [allElements]="taskField.professions"
                      (chosenElements)="setProfessions($event, i)"
                    ></prf-butterfly-control>
                  </div>
                </div>
                <div class="create-form-row">
                  <div class="create-form-column _1">
                    <label class="create-form-label">Обязательно ли прохождение диагностик на интересы и таланты*</label>
                  </div>
                </div>
                <div class="create-form-row">
                  <div class="create-form-column _1">
                    <label class="create-form-label">Текст для тултипа*</label>
                  </div>
                  <div class="create-form-column">
                    <input
                      type="text"
                      class="create-event-field w-input"
                      [class.error-field]="submitted && !taskField.tooltip"
                      placeholder="Текст для тултипа"
                      [name]="'tooltipCP' + i"
                      [formControlName]="'tooltipCP' + i"
                      [(ngModel)]="taskField.tooltip"
                    />
                    <mat-error *ngIf="submitted && !taskField.tooltip">
                      {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                    </mat-error>
                  </div>
                </div>

                <div class="create-form-row">
                  <div class="create-form-column _1">
                    <label class="create-form-label">Тип "Тиндера"</label>
                  </div>
                  <div class="create-form-column">
                    <mat-form-field class="full-width">
                      <mat-select
                        placeholder='Выберите тип "Тиндера"'
                        [(ngModel)]="taskField.tinderType"
                        [name]="'tinderType' + i"
                        [formControlName]="'tinderType' + i"
                      >
                        <mat-option [value]="TinderTypes.ByFields">По отраслям</mat-option>
                        <mat-option [value]="TinderTypes.ByInterests">На основе диагностики интересов</mat-option>
                        <mat-option [value]="TinderTypes.ByTalents">На основе диагностики способностей</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="create-form-row">
                  <mat-checkbox class="checkbox" [name]="'required' + i" [formControlName]="'required' + i" [(ngModel)]="taskField.required"
                    >Обязательное задание?</mat-checkbox
                  >
                </div>
              </ng-template>
              <ng-template [ngSwitchCase]="8">
                <div class="create-form-row">
                  <div class="create-form-column _1">
                    <label class="create-form-label">Этап примерки*</label>
                  </div>
                  <div class="create-form-column">
                    <prf-dropdown-with-filter
                      [items]="selectionStages"
                      (selectedItemEvent)="setProfessionTryingOnStage($event, i)"
                      [placeholder]="'Выберите этап для типа задания по примерке профессий'"
                      [selectedItemByDefault]="defaultTryingOnStage"
                    ></prf-dropdown-with-filter>
                    <mat-error *ngIf="submitted && !taskField.professionTryingOnStage">
                      {{ 'SHARED.FORM.FILL_DROPDOWN' | translate }}
                    </mat-error>
                  </div>
                </div>
                <div class="create-form-row">
                  <div class="create-form-column _1">
                    <label class="create-form-label">Текст для тултипа*</label>
                  </div>
                  <div class="create-form-column">
                    <input
                      type="text"
                      class="create-event-field w-input"
                      [class.error-field]="submitted && !taskField.tooltip"
                      placeholder="Текст для тултипа"
                      [name]="'tooltipCP' + i"
                      [formControlName]="'tooltipCP' + i"
                      [(ngModel)]="taskField.tooltip"
                    />
                    <mat-error *ngIf="submitted && !taskField.tooltip">
                      {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                    </mat-error>
                  </div>
                </div>
                <div class="create-form-row">
                  <mat-checkbox class="checkbox" [name]="'required' + i" [formControlName]="'required' + i" [(ngModel)]="taskField.required"
                    >Обязательное задание?</mat-checkbox
                  >
                </div>
              </ng-template>
              <ng-template [ngSwitchCase]="10">
                <div class="create-form-row">
                  <div class="create-form-column _1">
                    <label class="create-form-label">Внешний url задания*</label>
                  </div>
                  <div class="create-form-column">
                    <input
                      type="text"
                      class="create-event-field w-input"
                      [class.error-field]="submitted && !taskField.externalLessonLink"
                      [name]="'externalLink' + i"
                      placeholder="Внешний url занятия"
                      [formControlName]="'externalLessonLink' + i"
                      [(ngModel)]="taskField.externalLessonLink"
                    />
                    <mat-error *ngIf="submitted && !taskField.externalLessonLink">
                      {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                    </mat-error>
                  </div>
                </div>
                <div class="create-form-row">
                  <div class="create-form-column _1">
                    <label class="create-form-label">Текст для тултипа*</label>
                  </div>
                  <div class="create-form-column">
                    <input
                      type="text"
                      class="create-event-field w-input"
                      [class.error-field]="submitted && !taskField.externalLessonTooltip"
                      [name]="'externalLessonTooltip' + i"
                      placeholder="Текст для тултипа"
                      [formControlName]="'externalLessonTooltip' + i"
                      [(ngModel)]="taskField.externalLessonTooltip"
                    />
                    <mat-error *ngIf="submitted && !taskField.externalLessonTooltip">
                      {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                    </mat-error>
                  </div>
                </div>
                <div class="create-form-row">
                  <mat-checkbox class="checkbox" [name]="'required' + i" [formControlName]="'required' + i" [(ngModel)]="taskField.required"
                    >Обязательное задание?</mat-checkbox
                  >
                </div>
              </ng-template>
            </ng-container>
          </ng-template>
        </ng-container>
      </ng-template>
      <div class="edit-event-buttons-row">
        <input (click)="submit()" type="submit" value="Редактировать занятие" class="button green-btn w-button" />
        <a class="white-button edit-event w-button" [routerLink]="'/admin/lessons'"> Отмена </a>
      </div>
    </form>
  </div>
</div>
