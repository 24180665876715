<div class="navbar adm">
  <div class="logo-div">
    <a (click)="selectMain()" class="home-link w-inline-block" [class.mo-logo]="isMOStend"></a>
  </div>
  <div class="menu">
    <div class="menu-item-wrapper">
      <button
        type="button"
        class="menu-link adm school-2 w-inline-block"
        (click)="openSubmenu($event)"
        [class.w--current]="router.url.includes('/' + homeURL) || router.url.includes('/regions') || router.url.includes('/municipalities') || router.url.includes('/cities')"
      >
        Структура регионов
      </button>
      <div class="submenu-wrapper">
        <button
          type="button"
          class="submenu-button"
          (click)="this.hideProfile(); this.hideMobileMenu()"
          [routerLink]="['/admin/' + homeURL]"
        >
          {{ home }}
        </button>

        <button
          type="button"
          class="submenu-button"
          (click)="this.hideProfile(); this.hideMobileMenu()"
          [routerLink]="['/admin/regions']"
        >
          {{ 'SHARED.REGIONS' | translate }}
        </button>
        <button
          type="button"
          class="submenu-button"
          (click)="this.hideProfile(); this.hideMobileMenu()"
          [routerLink]="['/admin/municipalities']"
        >
          Муниципалитеты
        </button>
        <button type="button" class="submenu-button" (click)="this.hideProfile(); this.hideMobileMenu()" [routerLink]="['/admin/cities']">
          Города
        </button>
      </div>
    </div>

    <div class="menu-item-wrapper">
      <button
        type="button"
        class="menu-link adm _catalog w-inline-block"
        (click)="openSubmenu($event)"
        [class.w--current]="
          router.url.includes('profession') ||
          router.url.includes('/courses/') ||
          router.url.includes('events') ||
          router.url.includes('universit') ||
          router.url.includes('specialt') ||
          router.url.includes('programs')
        "
      >
        Каталог
      </button>
      <div class="submenu-wrapper">
        <button type="button" class="submenu-button" [routerLink]="['/admin/professions']">
          {{ 'HEADER.PROF' | translate }}
        </button>
        <button type="button" class="submenu-button" [routerLink]="['/admin/specialties']">Специальности</button>
        <button type="button" class="submenu-button" [routerLink]="['/admin/fields']">Отрасли</button>
        <button type="button" class="submenu-button" [routerLink]="['/admin/programs']">Программы СПО/ВО</button>
        <button type="button" class="submenu-button" [routerLink]="['/admin/chatbots']">Чатботы</button>
      </div>
    </div>

    <a
      class="menu-link internships w-inline-block"
      *ngIf="isInternshipsReady"
      [class.w--current]="internshipActive"
      [routerLinkActive]="['w--current']"
      [routerLink]="['/admin/internships']"
    >
      <div>Стажировки</div>
    </a>
    <a class="menu-link adm users w-inline-block" [routerLinkActive]="['w--current']" [routerLink]="['/admin/users']">
      <div>Пользователи</div>
    </a>

    <div class="menu-item-wrapper">
      <button
        type="button"
        class="menu-link adm _local_derivations w-inline-block"
        (click)="openSubmenu($event)"
        [class.w--current]="router.url.includes('career-education') || router.url.includes('lessons')"
      >
        Внеурочка
      </button>

      <div class="submenu-wrapper">
        <button type="button" class="submenu-button" [routerLink]="['/admin/career-education']">Курс учителя</button>
        <button type="button" class="submenu-button" [routerLink]="['/admin/lessons']">Уроки</button>
      </div>
    </div>

    <div class="menu-item-wrapper">
      <button
        type="button"
        class="menu-link adm system w-inline-block"
        (click)="openSubmenu($event)"
        [class.w--current]="router.url.includes('/admin/system') || router.url.includes('/admin/features')"
      >
        Система
      </button>

      <div class="submenu-wrapper">
        <button type="button" class="submenu-button" [routerLink]="['/admin/system']">Система</button>
        <button type="button" class="submenu-button" [routerLink]="['/admin/features']">Переключение функций</button>
      </div>
    </div>

    <a class="menu-link adm user-experience w-inline-block" target="_blank" rel="noopener noreferrer" href="https://demo.epoch.profilum.ru">
      Профпробы
    </a>

    <a class="menu-link adm test-editor w-inline-block last" target="_blank" rel="noopener noreferrer" [href]="testEditorDomain">
      Редактор тестов
    </a>

  </div>
</div>

<!---------------------- mobile menu --------------------------------->
<div class="mobile-menu" [style.display]="isShowMobileMenu ? 'block' : 'none'">
  <div
    [ngStyle]="{
      transform: 'translate3d(-100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);'
    }"
    class="mobile-menu-container"
    style="transform-style: preserve-3d"
  >
    <div class="mobile-menu-header">
      <div class="mobile-menu-button fixed" (click)="hideMobileMenu()">
        <div class="mobile-menu-icon-div">
          <div
            class="menu-line"
            style="
              transform: translate3d(0px, 4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
              transform-style: preserve-3d;
            "
          ></div>
          <div
            class="menu-line"
            style="
              transform: translate3d(0px, -4.5px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
              transform-style: preserve-3d;
            "
          ></div>
        </div>
      </div>
      <div class="mobile-menu-logo-div">
        <a (click)="selectMain()" class="mobile-menu-logo-link w-inline-block w--current" [class.mo-logo]="isMOStend">
          <img
            src="{{ isMOStend ? 'assets/images/dashboard/profilum-logo.svg' : '/assets/images/dashboard/profilum-new-logo.svg' }}"
            alt="Profilum logo"
          />
        </a>
      </div>
    </div>
    <div class="mobile-user-div">
      <a (click)="selectProfile()" class="user-dropdown mob w-inline-block">
        <div class="user-dropdown-toggle">
          <div class="user-photo mob" [ngStyle]="{ 'background-image': 'url(' + imagePath + ')' }"></div>
          <div class="user-name mob">{{ firstName }}</div>
        </div>
      </a>
    </div>

    <div class="menu-list-mobile">
      <div class="menu-item-wrapper">
        <button
          type="button"
          class="menu-link aschool-2 w-inline-block"
          (click)="openSubmenu($event)"
          [class.w--current]="router.url.includes('/' + homeURL) || router.url.includes('/regions') || router.url.includes('/municipalities') || router.url.includes('/cities')"
        >
          Структура регионов
        </button>

        <div class="submenu-wrapper">
          <button
            type="button"
            class="submenu-button"
            (click)="this.hideProfile(); this.hideMobileMenu()"
            [routerLink]="['/admin/' + homeURL]"
          >
            {{ home }}
          </button>

          <button
            type="button"
            class="submenu-button"
            (click)="this.hideProfile(); this.hideMobileMenu()"
            [routerLink]="['/admin/regions']"
          >
            {{ 'SHARED.REGIONS' | translate }}
          </button>
          <button
            type="button"
            class="submenu-button"
            (click)="this.hideProfile(); this.hideMobileMenu()"
            [routerLink]="['/admin/municipalities']"
          >
            Муниципалитеты
          </button>
          <button type="button" class="submenu-button" (click)="this.hideProfile(); this.hideMobileMenu()" [routerLink]="['/admin/cities']">
            Города
          </button>
        </div>
      </div>

      <div class="menu-item-wrapper">
        <button
          type="button"
          class="menu-link _catalog w-inline-block"
          (click)="openSubmenu($event)"
          [class.w--current]="
            router.url.includes('profession') ||
            router.url.includes('cours') ||
            router.url.includes('universit') ||
            router.url.includes('specialt')
          "
        >
          Каталог
        </button>
        <div class="submenu-wrapper">
          <button
            type="button"
            class="submenu-button"
            (click)="this.hideProfile(); this.hideMobileMenu()"
            [routerLink]="['/admin/professions']"
          >
            {{ 'HEADER.PROF' | translate }}
          </button>

          <button
            type="button"
            class="submenu-button"
            (click)="this.hideProfile(); this.hideMobileMenu()"
            [routerLink]="['/admin/specialties']"
          >
            Специальности
          </button>

          <button
            type="button"
            class="submenu-button"
            (click)="this.hideProfile(); this.hideMobileMenu()"
            [routerLink]="['/admin/fields']"
          >
            Отрасли
          </button>

          <button
            type="button"
            class="submenu-button"
            (click)="this.hideProfile(); this.hideMobileMenu()"
            [routerLink]="['/admin/programs']"
          >
            Программы СПО/ВО
          </button>

          <button
            type="button"
            class="submenu-button"
            (click)="this.hideProfile(); this.hideMobileMenu()"
            [routerLink]="['/admin/chatbots']"
          >
            Чатботы
          </button>
        </div>
      </div>

      <a
        class="menu-link internships w-inline-block"
        *ngIf="isInternshipsReady"
        [class.w--current]="internshipActive"
        [routerLinkActive]="['w--current']"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        [routerLink]="['/admin/internships']"
      >
        <div>Стажировки</div>
      </a>

      <a
        class="menu-link users w-inline-block"
        [routerLinkActive]="['w--current']"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        [routerLink]="['/admin/users']"
      >
        <div>Пользователи</div>
      </a>

      <div class="menu-item-wrapper">
        <button
          type="button"
          class="menu-link _local_derivations w-inline-block"
          (click)="openSubmenu($event)"
          [class.w--current]="router.url.includes('career-education') || router.url.includes('lessons')"
        >
          Внеурочка
        </button>

        <div class="submenu-wrapper">
          <button
            type="button"
            class="submenu-button"
            (click)="this.hideProfile(); this.hideMobileMenu()"
            [routerLink]="['/admin/career-education']"
          >
            Курс учителя
          </button>
          <button
            type="button"
            class="submenu-button"
            (click)="this.hideProfile(); this.hideMobileMenu()"
            [routerLink]="['/admin/lessons']"
          >
            Уроки
          </button>
        </div>
      </div>

      <div class="menu-item-wrapper">
        <button
          type="button"
          class="menu-link system w-inline-block"
          (click)="openSubmenu($event)"
          [class.w--current]="router.url.includes('/admin/system') || router.url.includes('/admin/features')"
        >
          Система
        </button>

        <div class="submenu-wrapper">
          <button type="button" class="submenu-button" [routerLink]="['/admin/system']">Система</button>
          <button type="button" class="submenu-button" [routerLink]="['/admin/features']">Переключение функций</button>
        </div>
      </div>

      <a
        class="menu-link user-experience w-inline-block"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        target="_blank"
        rel="noopener noreferrer"
        href="https://demo.epoch.profilum.ru"
      >
        Профпробы
      </a>

      <a
        class="menu-link test-editor w-inline-block"
        (click)="this.hideProfile(); this.hideMobileMenu()"
        target="_blank"
        rel="noopener noreferrer"
        [href]="testEditorDomain"
      >
        Редактор тестов
      </a>

    </div>
  </div>
</div>
