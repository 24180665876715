import { ILesson } from 'app/shared/interfaces/ilesson';
import { HttpService } from 'app/core/services/http.service';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Helper } from 'app/shared/common/helpers/helper';
import { IField, ISurvey } from 'app/pages/control-panel/admin/lessons/interfaces/interfaces';
import {
  ICourseMaterial,
  IDataForCreatingLesson,
  IStatus,
} from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import { IClass } from 'app/shared/interfaces/iclass.interface';
import { getScreeningTestsApiDomain } from '../../../../../../../../libs/profilum-configurations/tools/tools';
import { Services } from '../../../../../../../../libs/profilum-configurations/services/services';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LessonsService {
  private _dataForCreatingLesson: ReplaySubject<IDataForCreatingLesson | null>;

  constructor(
    private http: HttpService,
    private httpClient: HttpClient,
  ) {
    this._dataForCreatingLesson = new ReplaySubject<IDataForCreatingLesson | null>(1);
  }

  public set dataForCreatingLesson(data: IDataForCreatingLesson | null) {
    this._dataForCreatingLesson.next(data);
  }

  public get dataForCreatingLessonObserver(): Observable<IDataForCreatingLesson> {
    return this._dataForCreatingLesson.pipe(filter(data => data !== null));
  }

  public createLesson(lesson: { lesson: ILesson }): Observable<IStatus> {
    return this.http.post('/api/v1.0/lessons/lessons', lesson).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  public deleteLesson(id: string): Observable<IStatus> {
    return this.http.delete(`/api/v1.0/lessons/lessons?id=${id}`).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  public getAllLesson(): Observable<ILesson[]> {
    return this.http.get(`/api/v1.0/lessons/lessons/all`).pipe(
      map(r => r?.lessons ?? []),
      catchError(Helper.handleError),
    );
  }

  public getListLesson(): Observable<ILesson[]> {
    return this.http.get(`/api/v1.0/lessons/lessons/getlist`).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  public getOneLesson(id: string): Observable<ILesson> {
    return this.http.get(`/api/v1.0/lessons/lessons/one?id=${id}`).pipe(
      map(r => r?.lesson ?? null),
      catchError(Helper.handleError),
    );
  }

  public updateLesson(lesson: ILesson): Observable<IStatus> {
    return this.http.put(`/api/v1.0/lessons/lessons`, lesson).pipe(
      map(r => r),
      catchError(Helper.handleError),
    );
  }

  public getCourseMaterials(): Observable<ICourseMaterial[]> {
    return this.http.get('/api/v1.0/lessons/coursesmaterials/all').pipe(
      map(r => r?.courseMaterials ?? []),
      catchError(Helper.handleError),
    );
  }

  public getCourseMaterial(courseId: string): Observable<ICourseMaterial> {
    return this.http.get('/api/v1.0/lessons/coursesmaterials/one?id=' + courseId).pipe(
      map(r => r?.courseMaterial ?? null),
      catchError(Helper.handleError),
    );
  }

  public getTests(): Observable<ISurvey[]> {
    return this.httpClient
      .get(`${getScreeningTestsApiDomain(Services.Admin)}/api/v1-draft/survey/all-ids`, { withCredentials: true })
      .pipe(catchError(Helper.handleError));
  }

  public getTest(screeningTestId: string): Observable<ISurvey> {
    return this.httpClient
      .get(`${getScreeningTestsApiDomain(Services.Admin)}/api/v1-draft/survey/${screeningTestId}`, { withCredentials: true })
      .pipe(catchError(Helper.handleError));
  }

  public getFields(): Observable<IField[]> {
    return this.http.get('/api/v1.0/catalog/fields/all').pipe(
      map(r => r?.fields ?? []),
      catchError(Helper.handleError),
    );
  }

  public getField(fieldId: string): Observable<IField> {
    return this.http.get('/api/v1.0/catalog/fields/one?fieldId=' + fieldId).pipe(
      map(r => r?.field ?? null),
      catchError(Helper.handleError),
    );
  }

  public getSchoolClassCourses(schoolClassId: string): Observable<IClass> {
    return this.http
      .get('/api/v1.0/lessons/courseactivity/getcoursebyclass?schoolClassId=' + schoolClassId)
      .pipe(catchError(Helper.handleError));
  }

  public assignCourseForSchoolClass(schoolClassId: string, courseId: string) {
    const body = {
      courseActivity: {
        schoolClassId,
        courseId,
      },
    };
    return this.http.post('/api/v1.0/lessons/courseactivity/assigncourseforclass', body).pipe(catchError(Helper.handleError));
  }

  public updateCourseForSchoolClass(schoolClassId: string, courseId: string) {
    return this.http
      .post('/api/v1.0/lessons/courseactivity/updatecourseforclass', { schoolClassId, courseId })
      .pipe(catchError(Helper.handleError));
  }

  public deleteCourseForSchoolClass(schoolClassId: string) {
    return this.http
      .deleteWithObject('/api/v1.0/lessons/courseactivity/deletecourseforclass', { schoolClassId })
      .pipe(catchError(Helper.handleError));
  }
}
